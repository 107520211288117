
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      localStorage.setItem("token", "123");
      this.$router.push("/");
    },
  },
});
